import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/working_dir/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Row } from '../components/helpers';
import Headboard from '../../src/Icons/Headboard';
import Comforter from '../../src/Icons/Comforter';
import MattressProtector from '../../src/Icons/MattressProtector';
import Mattress from '../../src/Icons/Mattress';
import Pillow from '../../src/Icons/Pillow';
import Sheets from '../../src/Icons/Sheets';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "icons"
    }}>{`Icons`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import IconName from '@mfrm/mfcl/Icons/[Icon Name]'
`}</code></pre>
    <Playground __position={0} __code={'<Row>\n  <Sheets width={30} height={30} />\n  <MattressProtector width={30} height={30} />\n  <Mattress width={30} height={30} />\n  <Pillow width={30} height={30} />\n  <Comforter width={30} height={30} />\n  <Headboard width={30} height={30} />\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Row,
      Headboard,
      Comforter,
      MattressProtector,
      Mattress,
      Pillow,
      Sheets,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Row mdxType="Row">
    <Sheets width={30} height={30} mdxType="Sheets" />
    <MattressProtector width={30} height={30} mdxType="MattressProtector" />
    <Mattress width={30} height={30} mdxType="Mattress" />
    <Pillow width={30} height={30} mdxType="Pillow" />
    <Comforter width={30} height={30} mdxType="Comforter" />
    <Headboard width={30} height={30} mdxType="Headboard" />
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      